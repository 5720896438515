import React from "react";
import { Box } from "@mui/material";

// Props for the PunchCard component
interface PunchCardProps {
  icon: string;
  maxScans: number;
  scans: number;
}

// PunchCard component
const PunchCard: React.FC<PunchCardProps> = ({ icon, maxScans, scans }) => {
  // Calculate items per row
  const itemsPerRow = maxScans === 8 ? 4 : 5; // 4 for 8 scans, otherwise 5

  // Calculate the number of rows needed
  const numberOfRows = Math.ceil(maxScans / itemsPerRow);

  // Create an array of circle elements
  const circles = Array.from({ length: maxScans }, (_, index) => (
    <Box
      key={index}
      sx={{
        width: 32,
        height: 32,
        display: "inline-block",
        margin: "2px",
        borderRadius: "50%",
        backgroundColor: index < scans ? "transparent" : "none",
        backgroundImage: index < scans ? `url(${icon})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: index < scans ? "none" : "2px solid rgba(255, 0, 0, 0.5)",
      }}
    />
  ));

  return (
    <Box
      sx={{
        width: "fit-content",
        border: "1px solid #ddd",
        borderRadius: 2,
        padding: 1,
        display: "grid",
        gridTemplateColumns: `repeat(${itemsPerRow}, auto)`,
        gridTemplateRows: `repeat(${numberOfRows}, auto)`,
        gap: 0.5,
      }}
    >
      {circles}
    </Box>
  );
};

export default PunchCard;

import React from "react";
import { Typography, Link, Box, Divider, List, ListItem } from "@mui/material";

const PrivacyPolicy: React.FC = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h1" fontWeight="bold">
          Stomp LOYALTY PRIVACY POLICY
        </Typography>
        <Divider sx={{ mt: 1 }} />
        <Typography variant="body1" sx={{ mt: 1 }}>
          <span style={{ fontWeight: 400 }}>Last Updated: August 8, 2024.</span>
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          Stomp Loyalty, Inc. provides digital stamp card software and related
          services. We are dedicated to safeguarding the privacy of any Personal
          Information you share with us. In this Privacy Statement, "Personal
          Information" refers to data that pertains to an identified or
          identifiable individual, including details about your interactions
          with our Services (such as your name, email address, device
          information, and IP address). This Privacy Statement is designed to
          outline our information practices, detail the types of information we
          may collect, explain our intended use and sharing of that information,
          and describe how you can update or modify it.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ mb: 1 }}
        >
          1. What this Privacy Statement covers.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          This Privacy Statement covers the Personal Information we collect when
          you: (i) visit our websites that link to this Privacy Statement, such
          as{" "}
          <Link href="https://www.stomployalty.com" sx={{ fontWeight: 400 }}>
            https://www.stomployalty.com
          </Link>{" "}
          ,{" "}
          <Link href="https://www.stompscanner.com" sx={{ fontWeight: 400 }}>
            https://www.stompscanner.com
          </Link>{" "}
          , and{" "}
          <Link href="https://www.stompcards.com" sx={{ fontWeight: 400 }}>
            https://www.stompcards.com
          </Link>{" "}
          (the “Sites”); (ii) use our demo stamp cards, mobile apps (including
          the Stomp Loyalty Stamper app), or other software applications linked
          to this Privacy Statement; (iii) interact with our branded social
          media pages; (iv) visit our offices; (v) receive communications from
          us, including emails, phone calls, or texts; (vi) participate in our
          events or webinars; (vii) apply for a job with us; (viii) provide
          information related to potential business dealings with us; or (ix)
          register to use the Stomp Loyalty Platform as an authorized user
          (e.g., as an employee of one of our customers who has provided you
          with login access) or contact us for support (referred to as “Account
          Information” in this Statement). Collectively, these activities,
          including the Sites, are referred to as the “Services.” “You” refers
          to anyone who visits or uses the Services.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          Generally, for Personal Information collected directly from the Sites
          or other Services, Stomp Loyalty acts as the data controller. As a
          data controller, we determine the purposes and methods for processing
          this Personal Information.
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          Excluding Account Information, this Privacy Statement does not apply
          to our practices concerning the Personal Information we handle on
          behalf of our customers through the Stomp Loyalty Platform. In this
          role, we act as a data processor or service provider for our
          customers, and our privacy commitments are outlined in our agreements
          with them, including the Terms and Conditions or other agreements
          related to their access and use of the Stomp Loyalty Platform. We do
          not control or take responsibility for our customers' data practices.
          For privacy information about how our customers use the Stomp Loyalty
          Platform with their mobile apps, websites, emails, texts, or other
          digital properties, please contact the respective customer directly.
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          Additionally, our Services may include links to third-party websites.
          The privacy practices of these third-party sites are governed by their
          own statements and policies, as described in Section 11 of this
          Privacy Statement.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ mb: 1 }}
        >
          2. What Personal Information does Stomp Loyalty collect?
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          The type of Personal Information we collect depends on how you
          interact with Stomp Loyalty, as outlined in the following categories:
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 400, mb: 1 }}>
          <strong>2.1 Personal Information we collect directly.</strong>
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Site Usage:</strong> When you use our Services or complete
          forms on our Site, we might offer you options to receive specific
          information or Services, download content, or register for events or
          webinars, and we may request your contact details. The Personal
          Information we might collect includes, but is not limited to, your
          name, title, company name, industry, company address, phone number,
          email address, country, and details about your interactions with Stomp
          Loyalty’s content and publications. Our sites also use cookies and
          other technologies, as detailed in Section 5 below. When updating or
          installing our mobile applications or wallet passes, we may collect
          device information (such as manufacturer, model, and IP address) or
          app details (such as version, activation date, and update status).
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Registering for and Attending Events:</strong> If you register
          for or attend an event or meeting (in person or online), we may
          request your contact information (name and email address), job title,
          and employer. We may also retain recordings of the event in line with
          our Data Retention Policy and based on your consent if the event is
          recorded. With your consent, we may scan your attendee badge at events
          to collect information such as your name, title, company name,
          address, country, phone number, email address, and attendance record.
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Your Communications with Us:</strong> We collect Personal
          Information such as name, email address, phone number, mailing
          address, job title, marketing subscription status, and details about
          your engagement with Stomp Loyalty’s content and publications when you
          request information about our Services, engage with our content,
          request customer or technical support, apply for a job, or otherwise
          communicate with us.
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Account Information for Stomp Loyalty Platform:</strong> If
          you register to use the Stomp Loyalty Platform as an authorized user
          or sign up for a free account, we collect Personal Information
          including your name, email address, IP address, company name, company
          industry, and website details.
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Surveys:</strong> We may invite you to participate in surveys.
          If you choose to participate, you may be asked to provide information
          such as your name, email address, employer, job title, and other
          contact details.
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Social Media Content:</strong> We may offer forums, blogs, or
          social media pages where any content you post will be considered
          “public” and not subject to privacy protections.
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Visiting Our Offices:</strong> If you visit our offices, you
          may need to register as a visitor and provide your name, email
          address, phone number, company name, and the time and date of your
          arrival.
        </Typography>
      </Box>

      {/* Personal Information Collected from Others Section */}
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ mb: 1 }}
        >
          2.2 Personal Information collected from others.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Information from Other Sources:</strong> We may obtain
          Personal Information from various external sources, such as public
          databases, joint marketing partners, social media platforms, and other
          third parties who provide contact details for potential prospects or
          leads. When acquiring information from these third parties, we ensure
          that we obtain legal and contractual assurances that the information
          was obtained lawfully and with proper consent.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Billing Information:</strong> If we charge a fee for our
          Services and you are a business representative of one of our
          customers, we may collect billing, payment, and contract-related
          information through our third-party billing and payment processors.
          This may include your name, title, company name, company address,
          phone number or email address, country, and payment details such as
          account information.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          In certain cases, your credit or debit card or other payment method
          information may be processed by our payment provider, Stripe, Inc., to
          handle transactions for invoices related to our Services. The payment
          processor's terms and conditions and data protection agreements, which
          Stomp Loyalty has in place with Stripe as our data processor or
          service provider, govern these transactions.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ mb: 1 }}
        >
          3. How does Stomp Loyalty use Personal Information and what are the
          legal bases for these uses?
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          We utilize the Personal Information we collect from you or that you
          provide for various business purposes. Based on your interactions with
          us, we and our third-party service providers use your Personal
          Information in several ways, including:
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          <strong>Providing Requested Information and Services:</strong> We may
          use your Personal Information to fulfill your requests for programs,
          Services, or information. This includes:
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          Managing your information and accounts.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          Responding to your questions, comments, and requests.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          Granting access to specific areas and features of our Services.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          Linking or combining data from other sources to better understand your
          needs and enhance our Services.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          Enabling registration for events, contests, promotions, forum posts,
          or webinars, and handling related communications.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2" fontWeight="bold">
          Legal Bases for Using Your Personal Information:
        </Typography>
        <List>
          <ListItem sx={{ fontWeight: 400 }}>
            <Typography variant="body1" sx={{ fontWeight: 400 }}>
              Performance of a Contract: Using your Personal Information to
              process orders, payments, or deliver Services as requested.
            </Typography>
          </ListItem>
          <ListItem sx={{ fontWeight: 400 }}>
            <Typography variant="body1" sx={{ fontWeight: 400 }}>
              Legitimate Business Interests: Processing your requests and
              improving our Services. This includes detecting and preventing
              fraud, protecting security, and providing interest-based
              advertising.
            </Typography>
          </ListItem>
          <ListItem sx={{ fontWeight: 400 }}>
            <Typography variant="body1" sx={{ fontWeight: 400 }}>
              Consent: When required, obtaining your consent to process your
              Personal Information for specific purposes. You can withdraw your
              consent at any time, and we will cease processing your data for
              that purpose.
            </Typography>
          </ListItem>
        </List>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          <strong>Fulfilling Contractual Obligations:</strong> When you register
          for the Stomp Loyalty Platform, we may use your Personal Information
          for administrative purposes or to fulfill contractual obligations,
          including: (a) addressing your and prospective customers' requests
          related to the Stomp Loyalty Platform or other products you have
          requested; (b) providing access to the Stomp Loyalty Platform you have
          registered for; (c) performing internal quality control; (d)
          maintaining and enhancing our Services; (e) verifying identity; (f)
          sending communications regarding the Services, your account, or
          changes to Stomp Loyalty policies or terms; (g) processing payments;
          (h) offering technical and customer support; and (i) enforcing our
          Terms of Use, Acceptable Use Policy, or other agreements with you.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2" fontWeight="bold">
          Legal Bases for This Use:
        </Typography>
        <List>
          <ListItem sx={{ fontWeight: 400 }}>
            <Typography variant="body1" sx={{ fontWeight: 400 }}>
              Performance of a Contract: We use your Personal Information to
              fulfill our contractual obligations, such as processing orders,
              delivering Services, and handling payments.
            </Typography>
          </ListItem>
          <ListItem sx={{ fontWeight: 400 }}>
            <Typography variant="body1" sx={{ fontWeight: 400 }}>
              Legitimate Business Interests: We use your Personal Information to
              meet our contractual commitments and to enhance our Services,
              while also considering our interests and those of our customers in
              improving our offerings.
            </Typography>
          </ListItem>
        </List>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          We may use your Personal Information to send you materials about our
          offers, products, and services, including updates on new content or
          upcoming services. We might reach out to you via phone, postal mail,
          text, or email, in accordance with applicable laws. This includes: (a)
          customizing content, advertisements, and offers; (b) informing you
          about offers, products, and services that may interest you; (c)
          delivering our Services to you; (d) assessing interest in our
          Services; (e) tracking and analyzing trends, usage, and activities
          related to our Services; (f) for other purposes disclosed when you
          provide your Personal Information; or (g) as otherwise permitted with
          your consent. If you prefer not to have your Personal Information used
          for marketing purposes, you can contact us at any time to opt out, as
          detailed below.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          The legal bases for using your Personal Information under applicable
          data protection laws are:
        </Typography>
        <List>
          <ListItem sx={{ fontWeight: 400 }}>
            <Typography variant="body1" sx={{ fontWeight: 400 }}>
              Legitimate Business Interests: We use your Personal Information to
              improve our Services, detect and prevent fraud and abuse to
              protect the security of our customers, ourselves, or others, and
              in part to provide interest-based advertising.
            </Typography>
          </ListItem>
          <ListItem sx={{ fontWeight: 400 }}>
            <Typography variant="body1" sx={{ fontWeight: 400 }}>
              Your Consent: When we seek your consent to process your Personal
              Information for a specific purpose, such as linking or combining
              information from other sources, you can withdraw your consent at
              any time. Upon withdrawal, we will stop processing your data for
              that purpose.
            </Typography>
          </ListItem>
        </List>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          Research and Development: We may use your Personal Information to
          create non-identifiable data, which we may combine with information
          from other sources. This helps us enhance our current products and
          Services, develop new offerings, or create content related to industry
          trends, market reports, and analyses. Non-identifiable or aggregated
          data, which no longer identifies you or any individual, is used to
          measure interest in and usage of different parts of our Services, as
          well as trends in mobile applications and websites. This data is
          utilized for various purposes, including research, internal analysis,
          analytics, and creating benchmark or industry reports. We share this
          non-identifiable or aggregated information internally within Stomp
          Loyalty and with our customers or partners for their purposes,
          ensuring that it is formatted to prevent identification of
          individuals.
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            The legal bases for using your Personal Information under applicable
            data protection laws are:
          </Typography>
          <List>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                Legitimate Business Interests: We process your Personal
                Information based on our legitimate business interests, such as
                improving our Services, provided these interests do not outweigh
                your rights and freedoms.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                Consent: We may also process your Personal Information based on
                your consent for specific purposes, which we will communicate to
                you through a link, contract, or by combining information from
                other sources. You can withdraw your consent at any time, and we
                will stop processing your data for that purpose.
              </Typography>
            </ListItem>
          </List>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            Public Content:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            When you post content to our website forums or social media
            accounts, we may publish and make this content available to any user
            or visitor of our website or company social media pages.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            The legal bases for using your Personal Information under applicable
            data protection laws are:
          </Typography>
          <List>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                Consent: We process your Personal Information based on your
                consent when you choose to post content on our website forums or
                company social media pages. You can withdraw your consent at any
                time, and we will stop processing your data for that purpose.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                Legitimate Business Interests: We use your Personal Information
                based on our legitimate business interests, such as providing
                the requested information, detecting and preventing fraud and
                abuse to protect the security of our customers, ourselves, or
                others, and delivering interest-based advertising. Additionally,
                we may use it to improve our Services, provided these interests
                do not override your rights and freedoms.
              </Typography>
            </ListItem>
          </List>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <strong>Complying with Legal Obligations:</strong> As permitted by
            local laws, we may use your Personal Information to cooperate with
            public and government authorities, courts, or regulators. This
            includes complying with legal obligations, protecting our rights,
            preventing misuse or abuse of our Sites or Services, addressing
            potentially illegal activities and fraud, promoting the safety and
            security of our Services, and upholding our rights and those of
            others. We may also use your information to pursue legal remedies,
            limit damages, comply with judicial proceedings, court orders, or
            legal processes, and respond to lawful requests.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            The legal bases for using your Personal Information under applicable
            data protection laws are:
          </Typography>
          <List>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                We process your Personal Information to comply with legal
                requirements. For example, we may collect information such as
                your place of establishment and bank account details for
                identity verification purposes.
              </Typography>
            </ListItem>
          </List>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <strong>Other Uses:</strong> We may also use your Personal
            Information for additional purposes disclosed to you at the time of
            collection, with your consent, or to conduct our business and pursue
            legitimate interests. These purposes include registering for
            meetings, addressing and responding to comments or complaints,
            providing internal training, and managing recruitment, interviewing,
            and hiring processes.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            4. With whom does Stomp Loyalty share Personal Information?
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            We disclose your information to third parties only as necessary to
            fulfill your requests, for various professional or legitimate
            business purposes, including providing our Services, protecting
            ourselves or others, or in the event of a major business transaction
            such as a merger, sale, or asset transfer. We may also disclose your
            information as required or permitted by law. When transferring your
            personal data to third parties or service providers, we implement
            appropriate safeguards to ensure that data processing is secure and
            compliant with applicable data protection laws.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            4.1 Disclosures to provide our Services
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <strong>Service Providers:</strong> Stomp Loyalty may share your
            Personal Information with third-party service providers that assist
            us in performing services on our behalf. This includes:
          </Typography>
          <List>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>IT and Hosting:</strong> Provision of IT, hosting,
                backup, storage, and related services.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Requested Services:</strong> Providing information and
                services you have requested.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Payment Processing:</strong> Handling payment processing
                and collecting amounts owed to us.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Customer Service:</strong> Managing customer service
                activities.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            These service providers may access or process your Personal
            Information solely for the purpose of delivering these services.
            Payment information will be used and shared only to complete your
            order and may be stored by the service provider for future orders.
            We have established contracts with our service providers to ensure
            they use or share your Personal Information only as necessary to
            perform the contracted services or to comply with legal
            requirements.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Business Partners: Occasionally, Stomp Loyalty may collaborate with
            other companies to jointly offer products, services, or programs
            (such as webinars, events, or downloadable content). If you
            purchase, express interest in, or register for a jointly-offered
            product or service through Stomp Loyalty, we may share your Personal
            Information with our business partners. We do not control how our
            business partners use this information, and their use will be
            governed by their own privacy policies. If you prefer not to have
            your Personal Information shared in this way, you can choose not to
            purchase or express interest in these jointly-offered products or
            services, or opt out through any available mechanisms when the
            information is collected.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Affiliates: We may share your Personal Information with our
            affiliated companies.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Advertising Partners: Our Services may allow third-party advertising
            partners to use technologies and tracking tools to collect
            information about your activities and device (e.g., IP address,
            mobile identifiers, pages visited, location, time of day). We may
            combine this information with other data (such as demographic
            details and past purchase history) and share it with these partners.
            They may use this data, along with similar information from other
            websites, to deliver targeted advertisements to you on third-party
            sites within their networks, a practice known as "interest-based
            advertising" or "online behavioral advertising." For more
            information on how these technologies work, please refer to Section
            5. If you wish to opt out of sharing your Personal Information with
            third-party advertising partners, please follow the instructions in
            Section 11.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            4.2 Disclosures to protect us or others
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <strong>Protection of Stomp Loyalty and Others:</strong> We may
            disclose your Personal Information when we have a good faith belief
            that it is necessary to:
          </Typography>
          <List>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Legal Requirements:</strong> Comply with laws, court
                orders, or legal processes.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Public Authorities:</strong> Respond to lawful requests
                by public authorities, including national security or law
                enforcement requirements.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Litigation:</strong> Facilitate the discovery process in
                legal disputes.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Policy Enforcement:</strong> Enforce Stomp Loyalty
                policies or contracts.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Prevent Harm:</strong> Prevent physical harm or
                financial loss, or in connection with investigating or
                prosecuting suspected illegal activities.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Other Needs:</strong> Disclose when we believe it is
                otherwise necessary or advisable.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Additionally, for security purposes, server logs and other systems
            may be reviewed to detect unauthorized activity. In these cases,
            records containing Personal Information may be shared with service
            providers, third parties, and law enforcement to prevent fraud and
            assist in investigations related to unauthorized activities.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            4.3 Disclosure in the event of merger, sale, or other asset
            transfers
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Business Transfers: We reserve the right to disclose or transfer
            your Personal Information in the event of a proposed or actual
            transaction involving our business. This includes any
            reorganization, sale, lease, merger, joint venture, assignment,
            amalgamation, or acquisition of all or part of our business assets
            or shares. This also covers scenarios such as bankruptcy or similar
            proceedings.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            5. What device and usage data does Stomp Loyalty process?
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            We use minimal information-gathering tools, such as cookies and
            similar technologies, to automatically collect data from your
            computer or mobile device while you navigate our Sites or use our
            Services. This data may include Personal Information.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            5.1 Cookies and other tracking technologies in our Services.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
            As you navigate our Sites and use our Services, we may collect
            passive information through cookies and other tracking technologies.
            This data includes details about your computer, device, browsing
            actions, and patterns, such as IP addresses, cookie IDs,
            navigational data, content clicked, domain names, browser software,
            operating systems, device types, and other identifiers. This
            information helps us enhance the administration and quality of your
            experience with our Services (see the Your Privacy Rights section
            below for more details).
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <strong>Cookies:</strong> Cookies are small text files stored on
            your computer or mobile device by websites you visit. They are sent
            back to the originating site or to another site that recognizes them
            to create a record of your online activity. When you visit our
            Sites, we or an authorized third party may place cookies on your
            browser or device to collect information, including Personal
            Information, about your online behavior over time and across
            different sites. Cookies help us track usage, understand your
            preferences, and improve your browsing experience. For more
            information about cookies and web beacons, please visit{" "}
            <a
              href="http://www.allaboutcookies.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              All About Cookies
            </a>
            .
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            We use both session-based and persistent cookies:
          </Typography>
          <List>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Session-Based Cookies:</strong> These are temporary and
                exist only during your browsing session. They are deleted from
                your computer or device when you close your browser or turn off
                your device.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Persistent Cookies:</strong> These remain on your
                computer or device after you close your browser or turn off your
                device.
              </Typography>
            </ListItem>
          </List>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            Controlling Cookies
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            You can manage cookies at the individual browser level. However,
            disabling cookies may affect your ability to use certain features or
            functions of our Services.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            Types of Cookies on Our Sites
          </Typography>
          <List>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Essential Cookies:</strong> These cookies are crucial
                for the basic functionality of our Sites and cannot be disabled.
                Examples include session cookies for transmitting the website,
                authentication cookies, and security cookies. They also support
                features such as live chat. While you can configure your browser
                to block or alert you about these cookies, doing so may prevent
                some parts of the site from working properly.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Functional Cookies:</strong> These cookies enhance the
                functionality, performance, and services on our Sites. They
                allow us to measure and improve site performance by counting
                visits and traffic sources. Functional cookies also help us
                refine site features and provide more relevant communications,
                including marketing. They enable us to understand which pages
                are popular and how visitors interact with the Sites. We may use
                our own or third-party technologies to track and analyze usage
                to improve interactions and feature offerings.
              </Typography>
            </ListItem>
            <ListItem sx={{ fontWeight: 400 }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                <strong>Targeting Cookies:</strong> These cookies track your
                activity across different websites to gauge your interests and
                deliver targeted marketing. Our advertising partners may set
                these cookies through our Site to build a profile of your
                interests and show you relevant ads on other sites.
              </Typography>
            </ListItem>
          </List>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            SDKs
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            We may integrate third-party APIs and software development kits
            (SDKs) into our Services. These APIs and SDKs enable third parties,
            including analytics and advertising partners, to collect your
            personal information for various purposes, such as providing
            analytics services and delivering more relevant content to you.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            5.2 Social Media Features
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Stomp Loyalty may gather Personal Information to facilitate your use
            of online social media resources provided by Stomp Loyalty or third
            parties. This includes enabling you to post or share Personal
            Information with others. Be mindful of the Personal Information you
            disclose when using these social media tools.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Additionally, our Site may feature social media links such as
            Facebook, LinkedIn, Twitter, Instagram, Vimeo, and YouTube,
            including widgets like the “share this” button or other interactive
            elements. These features may collect your IP address, track which
            page you’re visiting, and set cookies for proper functionality.
            Whether hosted by a third party or directly on our Site, your
            interactions with these features are subject to the privacy policies
            of the respective companies providing them.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            5.3 Analytics
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            We may use Google Analytics, Google Analytics Demographics, and
            Interest Reporting, as well as similar services, to gather data on
            visitor behavior and demographics across some of our Sites. This
            information helps us enhance our website content. For details on
            Google Analytics, visit{" "}
            <a
              href="http://www.google.com/policies/privacy/partners/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google's Privacy Policy
            </a>
            . To opt out of Google’s data collection, visit{" "}
            <a
              href="http://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics Opt-out
            </a>
            . You can also manage your preferences for our analytics partners by
            adjusting the settings in the Cookie Settings link located at the
            bottom of the Site you are visiting.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            6. International Data Transfers
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            We store Personal Information sent or collected via our services in
            the United States or the European Economic Area, including on our
            cloud, servers, and those of our affiliates or service providers. To
            support our global operations, we may transfer, store, and access
            this information from locations where Stomp Loyalty operates, such
            as the United Kingdom or Thailand. This means your Personal
            Information could be processed in any of these countries, which may
            have data protection laws differing from or less stringent than
            those in your country. In such cases, we use international transfer
            mechanisms like the European Commission-approved Standard
            Contractual Clauses, adequacy decisions for specific countries, or
            seek your explicit consent for the transfer of your Personal
            Information, as appropriate. We also ensure that the recipient of
            your Personal Information provides adequate protection. For more
            details on the protections in place and to request a sample of the
            model contractual clauses, please contact us at{" "}
            <a href="mailto:privacy@stomployalty.com">
              privacy@stomployalty.com
            </a>
            .
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            7. What Security Measures Does Stomp Loyalty Have in Place?
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            The security of the Personal Information you provide is extremely
            important to us. We have established industry-standard technical,
            administrative, and physical measures to protect information on our
            servers from unauthorized access, alteration, disclosure, or
            destruction. It is your responsibility to keep your passwords
            confidential. If you suspect that your passwords or Personal
            Information may have been compromised, please contact us immediately
            at{" "}
            <a href="mailto:privacy@stomployalty.com">
              privacy@stomployalty.com
            </a>
            .
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            8. How Long Does Stomp Loyalty Retain Personal Information?
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            We keep the Personal Information we collect as outlined in this
            Privacy Statement for as long as you use our Services or as needed
            to achieve the purposes for which it was collected. This includes
            providing our products and Services, resolving disputes,
            establishing legal defenses, conducting audits, pursuing legitimate
            business interests, enforcing our agreements, and complying with
            applicable laws.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            9. Are Stomp Loyalty’s Sites Intended for Children?
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Our Services are not marketed to or intended for individuals under
            the age of 18. Stomp Loyalty does not knowingly solicit or collect
            Personal Information from children under 16. If you are a parent or
            guardian and think your child has provided us with Personal
            Information without your consent, please contact us using the
            information below, and we will take steps to remove such Personal
            Information from our Services.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            10. Your Privacy Rights
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            You have several options regarding your Personal Information. Here’s
            a summary of those choices, how to exercise them, and any
            limitations:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <strong>Opt-out:</strong> If you have consented to our processing of
            your Personal Information, you can withdraw that consent at any time
            by following the instructions provided in this section. We will
            inform you of any new purposes for which we intend to use your
            Personal Information and give you the chance to opt out. If your
            consent is legally or contractually required, we will comply with
            those requirements. To complete your request, you might need to log
            in to your account or provide additional information to verify your
            identity.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <strong>Access, Correction, and Deletion:</strong> You may have the
            right to access, correct, or delete Personal Information we hold
            about you. You can exercise these rights by contacting us at{" "}
            <a href="mailto:privacy@stomployalty.com">
              privacy@stomployalty.com
            </a>
            . We may need to verify your identity before responding to your
            request.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <strong>California Privacy Rights:</strong> California residents can
            exercise specific rights under the California Consumer Privacy Act
            (CCPA). For more information, please refer to our California Privacy
            Notice.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            11. Changes to This Privacy Statement
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            We may update this Privacy Statement from time to time. The revised
            statement will be effective as of the date posted. We encourage you
            to review this Privacy Statement periodically to stay informed about
            how we are protecting your information.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            12. Contact Us
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            If you have any questions about this Privacy Statement, our data
            practices, or wish to exercise your rights, please contact us at{" "}
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: 400, mt: 1 }}>
            Stomp Loyalty Inc.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            15027 82 Ave
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Surrey
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            BC, V3S7V6
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Canada
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            privacy@stomployalty.com
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            604-8683974
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;

import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

interface ContactUsDialogProps {
  open: boolean;
  onClose: () => void;
}

const ContactUsDialog: React.FC<ContactUsDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{ bgcolor: "#28282B", color: "#fffaef", textAlign: "center" }}
      >
        Contact Us
      </DialogTitle>
      <DialogContent sx={{ bgcolor: "#333333", color: "#fffaef", p: 3 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", p: 2 }}
          >
            <MailOutlineIcon sx={{ fontSize: 40, mb: 2 }} />
            <Typography variant="h6" sx={{ mb: 2 }}>
              We’d love to hear from you!
            </Typography>
          </Stack>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You can reach us at:
          </Typography>
          <Typography variant="body1">
            <a
              href="mailto:contact@stomployalty.com"
              style={{
                color: "#64b5f6",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              contact@stomployalty.com
            </a>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsDialog;

import React, { useState } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

import { fetchFirestoreCards } from "../lambda/serverGetCards";
import { LogoutDialog, RefreshDialog } from "../reusables/dialogs";

import stompBack1 from "../../assets/stomp-back1.png";
import stompBack2 from "../../assets/stomp-back2.svg";
import stompBack3 from "../../assets/stomp-back3.svg";

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const passkey = localStorage.getItem("passkey");
  const [openDialog, setOpenDialog] = useState(false);
  const [openRefreshDialog, setOpenRefreshDialog] = useState(false);
  const [background, setBackground] = useState<string>(
    localStorage.getItem("background") || "stomp3"
  );

  React.useEffect(() => {
    document.body.style.backgroundImage = `url(${
      background === "stomp1"
        ? stompBack1
        : background === "stomp2"
        ? stompBack2
        : stompBack3
    })`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "repeat-y";
    document.body.style.backgroundPosition = "center";
    document.body.style.width = "100vw";
  }, [background]);

  const fetchStoreCards = async (forceReset: boolean = false) => {
    const email = localStorage.getItem("email");
    const passkey = localStorage.getItem("passkey");
    if (email && passkey) {
      try {
        setOpenRefreshDialog(false);
        await fetchFirestoreCards(email, passkey, forceReset);
        window.location.reload();
      } catch (error) {
        console.error("Error fetching store cards: ", error);
      }
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRefresh = () => {
    setOpenRefreshDialog(true);
  };

  const cancelRefresh = () => {
    setOpenRefreshDialog(false);
  };

  const handleLogout = () => {
    setOpenDialog(true);
  };

  const confirmLogout = () => {
    handleMenuClose();
    localStorage.clear();
    setOpenDialog(false);
    window.location.reload();
  };

  const cancelLogout = () => {
    setOpenDialog(false);
  };

  const handleBackgroundChange = () => {
    const newBackground =
      background === "stomp2"
        ? "stomp3"
        : background === "stomp3"
        ? "stomp1"
        : "stomp2";

    setBackground(newBackground);
    localStorage.setItem("background", newBackground);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Stomp Cards: The Digital Wallet to Stomp Loyalty</title>
        <meta
          name="description"
          content="Stomp Cards is your app-less wallet to access all your Stomp Loyalty punch cards. If you prefer a traditional app layout, please install our website under settings with the add to Home Screen Button!"
        />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            outlineStyle: "solid",
            borderRadius: "12px",
            outlineWidth: "4px",
            outlineColor: "#FFA39D",
          }}
        >
          <Box
            sx={{
              outlineStyle: "double",
              borderRadius: "12px",
              outlineWidth: "10px",
              outlineColor: "#FF9B75",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.95)",
                padding: "8px 16px",
                borderRadius: "12px",
                textAlign: "center",
                fontWeight: "bold",
                letterSpacing: "1.5px",
                color: "#333",
                textShadow: "2px 2px 8px rgba(0, 0, 0, 0.3)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                zIndex: 1,
              }}
            >
              Stomp Cards
            </Typography>
          </Box>
        </Box>

        {passkey && (
          <div>
            <IconButton
              onClick={handleMenuOpen}
              color="inherit"
              aria-label="settings"
              sx={{
                position: "absolute",
                right: 20,
                top: "45%",
                transform: "translateY(-50%)",
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
              }}
            >
              <SettingsIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ marginTop: "2px" }}
            >
              <MenuItem>
                <Button
                  sx={{
                    width: "100px",
                    backgroundColor:
                      background === "stomp1"
                        ? "#b20058"
                        : background === "stomp2"
                        ? "#000000"
                        : "#0b1c2a",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor:
                        background === "stomp1"
                          ? "#8e0046"
                          : background === "stomp2"
                          ? "#333333"
                          : "#081621",
                    },
                  }}
                  variant="contained"
                  onClick={handleBackgroundChange}
                >
                  STOMP
                </Button>
              </MenuItem>

              <MenuItem>
                <Button
                  sx={{ width: "100px" }}
                  variant="contained"
                  color="success"
                  onClick={handleRefresh}
                >
                  Refresh
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  sx={{ width: "100px" }}
                  variant="contained"
                  color="error"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </MenuItem>
            </Menu>
          </div>
        )}

        <LogoutDialog
          open={openDialog}
          onCancel={cancelLogout}
          onConfirm={confirmLogout}
        />
        <RefreshDialog
          open={openRefreshDialog}
          onCancel={cancelRefresh}
          onConfirm={() => fetchStoreCards(true)}
        />
      </Box>
    </HelmetProvider>
  );
};

export default Header;

const LAMBDA_ENDPOINT =
  "https://xvtkpax6gnowyjjjinrwtrpsee0cxvtj.lambda-url.us-east-1.on.aws/";

export type StoreCard = {
  id: string;
  storeName: string;
  city: string;
  province: string;
  scans: number;
  maxScans: number;
  reward: string;
  titleImage: string;
  cardIcon: string;
  socialURL: string;
  storeNumber: string;
  disclaimer: string;
};

export const fetchFirestoreCards = async (
  email: string,
  passkey: string,
  forceReset = false
) => {
  try {
    // Build the query parameters
    const params = new URLSearchParams({ email, passkey });
    if (forceReset) {
      params.append("forceReset", "true");
    }

    // Make the HTTP GET request to the Lambda function
    const response = await fetch(`${LAMBDA_ENDPOINT}?${params.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Error fetching store cards: ${response.statusText}`);
    }

    // Parse the JSON response
    const data = await response.json();

    // Extract userId and storeCards from the response
    const { userId, storeCards } = data;

    if (storeCards.includes("incorrect-credentials")) {
      return [];
      // Handle the case where the passkey is incorrect
    }

    // Store userId in localStorage
    localStorage.setItem("qrID", userId);

    // Optionally store the cards in local storage (if needed)
    if (storeCards && storeCards.length > 0) {
      localStorage.setItem("storeCards", JSON.stringify(storeCards));
    } else {
      return getLocalCards(); // Return from local storage if no storeCards were returned
    }

    return storeCards; // Return storeCards for further use
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const getLocalCards = (): StoreCard[] => {
  const storedData = localStorage.getItem("storeCards");
  console.log("Getting local data...");
  if (storedData) {
    return JSON.parse(storedData);
  }
  return [];
};

import { Box, Typography, Container, Divider } from "@mui/material";

const FairUsePolicy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h1" fontWeight="bold">
          Fair Use Policy
        </Typography>
        <Divider sx={{ mt: 1 }} />
        <Typography variant="body1" sx={{ mt: 1 }}>
          Defined fair use policy for Stomp Loyalty punch card suite.
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Last updated: 06 August 2024
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2" fontWeight="bold">
          Introduction
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          To ensure that all our customers enjoy the full benefits of Stomp
          Loyalty, we have implemented a Fair Use Policy designed to provide an
          optimal experience for everyone. Excessive issuance of digital cards
          by some users could negatively impact network quality and degrade the
          service for the majority. Our goal is to maintain high-quality
          performance across the board, which is why we continuously monitor
          Stomp Loyalty usage on a monthly basis.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          The Fair Use Policy sets limits on the number of passes to prevent any
          single user from adversely affecting the system. This policy applies
          to all Stomp Loyalty services, ensuring that every user receives the
          best possible experience while keeping everything running smoothly.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2" fontWeight="bold">
          Why do we have a Fair Use Policy?
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          During peak times, many customers simultaneously use the shared
          network bandwidth, which can impact service quality. Most of our
          customers use their service responsibly, with their digital stamp card
          volume corresponding to the number of visitors to their store.
          However, a small minority misuse Stomp Loyalty by sending their
          digital stamp card links to thousands of people, including those far
          from their business location or not likely to be customers. This
          misuse degrades service quality for everyone, slowing access to
          accounts, especially during busy periods.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Despite the fact that only a few customers misuse the service, their
          actions significantly impact overall performance. Our Fair Use Policy
          is designed to address such inappropriate use and ensure that Stomp
          Loyalty remains equitable and efficient for all users.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2" fontWeight="bold">
          How does the Fair Use Policy work?
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Under this policy, we have established fair usage levels for unlimited
          digital loyalty cards. These levels are set higher than typical usage
          to ensure that the vast majority of customers remain unaffected by the
          Fair Use Policy. This approach helps accommodate regular use while
          addressing any potential misuse that could impact overall service
          quality.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2" fontWeight="bold">
          How do I know if the Fair Use Policy affects me?
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          The Fair Use Policy applies to all our customers. However, it will
          only actually affect you if you are one of the very few customers who
          abuse our services.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2" fontWeight="bold">
          Our Rights
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          If, upon reaching the usage levels specified in the Fair Use Policy
          for your plan, your excessive or unreasonable use continues, Stomp
          Loyalty may, without further notice to you, suspend or limit the
          service (or any feature of it) for any period we think is reasonably
          necessary; and/or terminate your agreement in accordance with the
          relevant section of the Terms of Service.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          If you are found to be in breach of this Fair Use Policy, we may reach
          out to discuss adjusting your usage to ensure it aligns with the
          policy. We reserve the right to modify the terms of the Fair Use
          Policy periodically without prior notice.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Should your usage exceed the levels specified in your plan as outlined
          in the Fair Use Policy, and if excessive or unreasonable use
          continues, Stomp Loyalty may suspend or restrict the service (or any
          of its features) for a duration deemed necessary. Additionally, we may
          terminate your agreement in accordance with the relevant section of
          the Terms of Service, without further notice.
        </Typography>
      </Box>
    </Container>
  );
};

export default FairUsePolicy;

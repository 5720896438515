// App.tsx
import React from "react";

import { Box } from "@mui/material";

import CardsTable from "./components/cards-table/cardsTable";
import Header from "./components/main/header";
import SignInForm from "./components/cards-table/cardsTableSignIn";
import Footer from "./components/main/footer";

const App: React.FC = () => {
  const email = localStorage.getItem("email");

  return (
    <Box>
        <Header />
        {!email ? (
          <SignInForm />
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <CardsTable />
          </Box>
        )}
        <Footer />
    </Box>
  );
};

export default App;

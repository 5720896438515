// CardsTable.tsx
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Collapse,
  Box,
  Card,
  Dialog,
  DialogContent,
  CardMedia,
  TextField,
  Snackbar,
  Skeleton,
  Stack,
} from "@mui/material";
import { ExpandMore, ExpandLess, QrCode2 } from "@mui/icons-material";
import { fetchFirestoreCards, StoreCard } from "../lambda/serverGetCards";
import { QrCode } from "../qrCode/qrCreator";
import PunchCard from "./punchCard";
import defaultIcon from "../../assets/defaultIcon.svg";

const CardsTable: React.FC = () => {
  const [storeCards, setStoreCards] = useState<StoreCard[]>([]);
  const [filteredCards, setFilteredCards] = useState<StoreCard[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [openCardId, setOpenCardId] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const fetchStoreCards = async (forceReset: boolean = false) => {
    const email = localStorage.getItem("email");
    const passkey = localStorage.getItem("passkey");
    if (!email || !passkey) {
      localStorage.clear();
      window.location.reload();
    }
    const storedStoreCards = JSON.parse(
      localStorage.getItem("storeCards") || "[]"
    );
    if (!storedStoreCards.length || !email) {
      forceReset = true;
    }

    if (email && passkey) {
      try {
        // const cards = await getFirestoreCards(passkey, forceReset);
        const cards = await fetchFirestoreCards(email, passkey, forceReset);
        if (cards.length === 0) {
          setSnackbarOpen(true);
        }
        setStoreCards(cards);
        setFilteredCards(cards);
      } catch (error) {
        console.error("Error fetching store cards: ", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchStoreCards();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = storeCards.filter((card) =>
      card.storeName.toLowerCase().includes(query)
    );

    if (filtered.length === 0) {
      setSnackbarOpen(true);
    }

    setFilteredCards(filtered);
  };

  const toggleCard = (id: string) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const openQrDialog = () => {
    setQrDialogOpen(true);
  };

  const closeQrDialog = () => {
    setQrDialogOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        marginBottom: "50px",
      }}
    >
      <Card
        sx={{
          padding: "20px",
          maxWidth: "400px",
          width: "90%",
          outlineStyle: "solid",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={openQrDialog}
            color="inherit"
            aria-label="qrOpen"
            sx={{
              position: "fixed",
              zIndex: 1,
              bottom: (theme) => theme.spacing(2),
              marginBottom: "30px",
              outline: "solid",
              backgroundColor: "#ffa5d6",
              borderRadius: "50%",
              padding: "8px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "#FF9CD2",
              },
            }}
          >
            <QrCode2 sx={{ fontSize: "42px" }} />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextField
            label="Search Store"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            autoComplete="off"
            sx={{ marginBottom: "5px", flexGrow: 1 }}
          />
        </Box>
        <TableContainer component={Paper} sx={{ margin: "1px 0" }}>
          <Table>
            <TableBody>
              {loading
                ? // Render skeletons while loading
                  Array.from(new Array(3)).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton variant="text" width="80%" height={30} />
                      </TableCell>
                    </TableRow>
                  ))
                : // Render actual data when loading is complete
                  filteredCards.map((card) => (
                    <React.Fragment key={card.id}>
                      <TableRow
                        hover
                        onClick={() => toggleCard(card.id)}
                        sx={{ cursor: "pointer", position: "relative" }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <Typography variant="h6">
                              {card.storeName}
                            </Typography>
                            {card.scans >= card.maxScans && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  fontSize: "8px",
                                  marginTop: "-48px",
                                  right: -12,
                                  backgroundColor: "#ffeb3b",
                                  color: "#000",
                                  padding: "2px 5px",
                                  borderRadius: "5px",
                                  boxShadow: "0 0 10px rgba(255, 193, 7, 0.5)",
                                  fontWeight: "bold",
                                }}
                              >
                                Reward Available
                              </Box>
                            )}
                            <IconButton>
                              {openCardId === card.id ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Collapse
                            in={openCardId === card.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              margin={1}
                              sx={{
                                outline: "solid",
                                backgroundColor: "#ffecef",
                                marginTop: "23px",
                                marginBottom: "23px",
                                borderRadius: "10px",
                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.7)",
                              }}
                            >
                              {card.titleImage && (
                                <CardMedia sx={{ maxHeight: "300px" }}>
                                  <img
                                    src={card.titleImage}
                                    alt={card.storeName}
                                    style={{
                                      width: "100%",
                                      borderTopRightRadius: "10px",
                                      borderTopLeftRadius: "10px",
                                    }}
                                  />
                                </CardMedia>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: 1,
                                  overflow: "hidden",
                                }}
                              >
                                {card.cardIcon ? (
                                  <PunchCard
                                    icon={card?.cardIcon || defaultIcon}
                                    maxScans={card.maxScans}
                                    scans={card.scans}
                                  />
                                ) : (
                                  <Skeleton
                                    variant="rectangular"
                                    width={200}
                                    height={60}
                                  />
                                )}
                              </Box>
                              <Box sx={{ marginLeft: "10px" }}>
                                <Typography variant="subtitle1">
                                  <strong>City:</strong> {card.city}
                                </Typography>
                                <Typography variant="subtitle1">
                                  <strong>Current Scans:</strong> {card.scans}
                                </Typography>
                                <Typography variant="subtitle1">
                                  <strong>Scans Required for Reward:</strong>{" "}
                                  {card.maxScans}
                                </Typography>
                                <Typography variant="subtitle1">
                                  <strong>Reward:</strong> {card.reward}
                                </Typography>
                                {card.disclaimer && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "grey",
                                      cursor: "pointer",
                                      marginTop: 0,
                                      marginBottom: 1,
                                    }}
                                    onClick={() =>
                                      setShowDisclaimer(!showDisclaimer)
                                    }
                                  >
                                    <Stack direction="column" spacing={1}>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ textDecoration: "underline" }}
                                      >
                                        Terms and Conditions:
                                      </Typography>
                                      <Collapse in={showDisclaimer}>
                                        <Box sx={{ marginTop: -2 }}>
                                          <ul>
                                            {card.disclaimer
                                              .split("\n")
                                              .map((point: any, index: any) => (
                                                <li key={index}>{point}</li>
                                              ))}
                                          </ul>
                                        </Box>
                                      </Collapse>
                                    </Stack>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={qrDialogOpen} onClose={closeQrDialog}>
        {/* QR Code Dialog */}
        <DialogContent>
          <QrCode />
        </DialogContent>
      </Dialog>

      {/* No Stores Found Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="No stores found."
      />
    </div>
  );
};

export default CardsTable;

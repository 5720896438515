import React from "react";
import { Dialog, DialogContent, Box } from "@mui/material";
import FairUsePolicy from "./policies/fairUsePolicy";
import TermsAndConditions from "./policies/termsAndConditions";
import PrivacyPolicy from "./policies/privacyPolicy";

interface PolicyContentBoxProps {
  open: boolean;
  onClose: () => void;
  policyType: string;
}

const PolicyContentBox: React.FC<PolicyContentBoxProps> = ({
  open,
  onClose,
  policyType,
}) => {
  // Choose the policy component to render based on policyType
  const renderPolicyContent = () => {
    switch (policyType) {
      case "fairUse":
        return <FairUsePolicy />;
      case "termsAndConditions":
        return <TermsAndConditions />;
      case "privacyPolicy":
        return <PrivacyPolicy />;
      default:
        return <Box>Policy not found.</Box>;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "12px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogContent sx={{ padding: "2%" }}>
        <Box
          sx={{
            mt: 2,
            maxHeight: "60vh",
            overflowY: "auto",
            marginTop: 1,
            marginBottom: 1,
          }}
        >
          {renderPolicyContent()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PolicyContentBox;

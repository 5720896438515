"use client";

import * as React from "react";
import { Box, Card, CardContent } from "@mui/material";
import QRCode from "react-qr-code";

export function QrCode(): React.JSX.Element {
  const qrID = localStorage?.getItem("qrID") || "";

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "4px 4px",
          marginBottom: "-20px",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={256}
          height={256}
          bgcolor="white"
          borderRadius={"0px"}
        >
          <QRCode size={256} value={qrID} />
        </Box>
      </CardContent>
    </Card>
  );
}

// SignInForm.tsx
import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

const SignInForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [passkey, setPasskey] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    localStorage.setItem("email", email);
    localStorage.setItem("passkey", passkey);
    window.location.reload();
  };

  return (
    <Card sx={{ maxWidth: 400, margin: "0 auto", mt: 5 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Sign In
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            mt: 1,
          }}
        >
          <TextField
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="passkey"
            label="Passkey"
            type="password"
            value={passkey}
            onChange={(e) => setPasskey(e.target.value)}
            required
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SignInForm;

import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface PolicyBoxProps {
  open: boolean;
  onClose: () => void;
  onPolicyClick: (policyType: string) => void;
}

const PolicyBox: React.FC<PolicyBoxProps> = ({
  open,
  onClose,
  onPolicyClick,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "12px",
          p: 1,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h6" component="div">
          Legal Information
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 27, top: 16 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0.5 }}>
        <Typography variant="body1" paragraph>
          Please review our legal documents for more information:
        </Typography>
        <Typography variant="body2" paragraph>
          <Link
            href="#"
            onClick={() => onPolicyClick("privacyPolicy")}
            color="primary"
          >
            Privacy Policy
          </Link>
        </Typography>
        <Typography variant="body2" paragraph>
          <Link
            href="#"
            onClick={() => onPolicyClick("fairUse")}
            color="primary"
          >
            Fair Use Policy
          </Link>
        </Typography>
        <Typography variant="body2" paragraph>
          <Link
            href="#"
            onClick={() => onPolicyClick("termsAndConditions")}
            color="primary"
          >
            Terms and Conditions
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default PolicyBox;

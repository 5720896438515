import { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Button,
  IconButton,
  Drawer,
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";

import PolicyBox from "../../Legals/policyBox";
import PolicyContentBox from "../../Legals/policyContent";
import ContactUsDialog from "./contactUs";

const Footer = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [legalsOpen, setLegalsOpen] = useState(false);
  const [policyContentOpen, setPolicyContentOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState<string | null>(null);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleLegalsOpen = () => {
    setLegalsOpen(true);
    setDrawerOpen(false);
  };

  const handleLegalsClose = () => {
    setLegalsOpen(false);
  };

  const handlePolicyClick = (policyType: string) => {
    setSelectedPolicy(policyType);
    setPolicyContentOpen(true);
    handleLegalsClose(); // Close the PolicyBox dialog
  };

  const handlePolicyContentClose = () => {
    setPolicyContentOpen(false);
  };

  const handleContactDialogOpen = () => {
    setContactDialogOpen(true);
    handleDrawerToggle();
  };

  const handleContactDialogClose = () => {
    setContactDialogOpen(false);
  };

  return (
    <>
      {/* Main Footer Content */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          bgcolor: "#212121",
          color: "#757575",
          height: drawerOpen ? "auto" : 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          transition: "height 0.3s ease",
        }}
      >
        {!drawerOpen && (
          <Typography
            variant="caption"
            sx={{ color: "#757575", fontSize: "0.75rem" }}
          >
            <span style={{ opacity: 0.5 }}>Powered by </span>
            <span style={{ fontWeight: "bold" }}>Stomp Loyalty</span>
          </Typography>
        )}
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ color: "#757575", p: 0, ml: 0 }}
        >
          {drawerOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      </Box>

      {/* Drawer Content */}
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            height: "auto",
            maxHeight: "50vh", // Adjust the height as needed
            overflowY: "auto",
            bgcolor: "#f0f0f0",
            color: "text.primary",
            p: 1,
            borderTop: "1px solid #424242",
          },
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ textAlign: { xs: "center", sm: "left" } }}
            >
              <Button
                onClick={handleContactDialogOpen}
                variant="outlined"
                color="primary"
              >
                Contact Us
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
              <Link href="#" onClick={handleLegalsOpen} underline="hover">
                Legals
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ textAlign: { xs: "center", sm: "right" } }}
            >
              <Typography variant="body2" color="textSecondary">
                <span style={{ opacity: 0.7 }}>Powered by </span>
                <Link
                  href="https://www.stomployalty.com"
                  underline="hover"
                  style={{ fontWeight: "bold" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stomp Loyalty
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Drawer>

      {/* PolicyBox Dialog */}
      {legalsOpen && (
        <PolicyBox
          open
          onClose={handleLegalsClose}
          onPolicyClick={handlePolicyClick}
        />
      )}

      {/* PolicyContentBox Dialog */}
      {selectedPolicy && (
        <PolicyContentBox
          open={policyContentOpen}
          onClose={handlePolicyContentClose}
          policyType={selectedPolicy}
        />
      )}
      {/* ContactUsDialog */}
      <ContactUsDialog
        open={contactDialogOpen}
        onClose={handleContactDialogClose}
      />
    </>
  );
};

export default Footer;
